<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-date-picker
            v-model="searchForm.date"
            type="date"
            :clearable="false"
            value-format="yyyy-MM-dd"
            placeholder="日期"
            size="small"
            style="width:140px;margin-right:15px;"
        ></el-date-picker>
        <el-input v-model="searchForm.departmentName" placeholder="请输入项目名称" size="small" style="width:140px;" clearable></el-input>
      </div>
      <div class="search" style="margin-bottom: 10px">
        <el-button type="primary" size="small" style="width:80px;" @click="exportTaiBan">导出</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="searchTaiBan">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table :data="TaiBantableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column align="center"  prop="departmentName" label="项目名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center"  prop="totalCount" label="吊篮总数"></el-table-column>
        <el-table-column align="center"  prop="checkCount" label="应巡检数量"></el-table-column>
        <el-table-column align="center"  prop="upCheckingCount" label="上部已巡检数量"></el-table-column>
        <el-table-column align="center"  prop="downCheckingCount" label="下部已巡检数量"></el-table-column>
        <el-table-column align="center"  prop="upNotCheckCount" label="上部未巡检数量"></el-table-column>
        <el-table-column align="center"  prop="downNotCheckCount" label="下部未巡检数量"></el-table-column>
        <el-table-column align="center"  prop="upCheckRate" label="上部巡检率">
          <template slot-scope="{row}">
            <span>{{ row.upCheckRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column align="center"  prop="downCheckRate" label="下部巡检率">
          <template slot-scope="{row}">
            <span>{{ row.downCheckRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column align="center"  prop="useCount" label="当天在用吊篮数量"></el-table-column>
        <el-table-column align="center"  prop="useRate" label="当天吊篮使用率">
          <template slot-scope="{row}">
            <span>{{ row.useRate }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import apis from '../api/security-check.js'
import {downloadFile} from '@/utils/utils'
import BaseURL from '@/apis/baseurl'
export default {
  name: "two",

  components: {},

  props: {},

  data() {
    Date.prototype.Format = function (fmt) { //author: meizz
      var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
      };
       if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
          for (var k in o)
             if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    }
    return {
      searchForm: {
        departmentName: '',
        date: (new Date()).Format('yyyy-MM-dd')
      },
      TaiBantableData: []
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem'])
  },

  watch: {
    companyId() {
      this.initTaiBan()
    }
  },

  mounted() {
    this.initTaiBan()
  },

  methods: {
    initTaiBan() {
      const params = {
        departmentCode: this.optionItem.departmentCode,
        departmentName: this.searchForm.departmentName,
        date: this.searchForm.date
      }
      apis.checkSummary(params).then(res => {
        this.TaiBantableData = res
      })
    },
    searchTaiBan() {
      this.initTaiBan()
    },
    exportTaiBan(){
      const params = {
        departmentCode: this.optionItem.departmentCode,
        date: this.searchForm.date,
        departmentName: this.searchForm.departmentName
      }
      // const url = BaseURL +`/pc/hangingBasket/exportByYear?departmentCode=${params.departmentCode}&year=${params.year}&vendor=${params.vendor}`
      const url = BaseURL + `/admin/public/exportCheckSummary?departmentCode=${params.departmentCode}&date=${params.date}&departmentName=${params.departmentName}`
      downloadFile(url, {}, '吊篮巡检汇总台账.xls')
    },
  }
}
</script>
